<template>
  <div class="Site PageBox">
    <van-nav-bar
            fixed
            :border="false"
            :title="$t('common2[1]')"
            left-arrow
            @click-left="$router.go(-1)"
    />
<!--    <van-image width="100%" fit="contain" src="./static/images/customer.png" />-->
    <div class="ScrollBox" style="height: 600px">
      <van-button color="#fff"
                  size="large" style="margin: 20px;width:calc(100% - 40px);height: 85px;display: flex" @click="openUrl">
        <van-grid :border="false" :column-num="1" icon-size="50" gutter="5" direction="horizontal">
          <van-icon name="./static/icon/customer.png" size="45"/>
          <div style="margin-top: 15px;margin-left: 20px;color:#333"> {{$t('common2[3]')}}</div>
        </van-grid>
      </van-button>
<!--      <van-button color="#252D30"-->
<!--                  size="large" style="margin: 10px;width: 95%;height: 85px;display: flex" @click="doShow3">-->
<!--        <van-grid :border="false" :column-num="1" icon-size="50" gutter="5" direction="horizontal">-->
<!--          <van-icon name="./static/icon/customer.png" size="45"/>-->
<!--          <div style="margin-top: 15px;margin-left: 20px">-->
<!--            {{$t('common2[3]')}}3-->
<!--          </div>-->
<!--        </van-grid>-->
<!--      </van-button>-->

      <!--      <van-button plain color="#F4D34F"-->
<!--                  size="large" style="margin: 10px;width: 95%;height: 85px;display: flex" @click="doShow">-->
<!--        <van-grid :border="false" :column-num="1" icon-size="50" gutter="5" direction="horizontal">-->
<!--          <van-grid-item icon="./static/icon/customer1.png" :text="$t('common2[4]')">-->
<!--          </van-grid-item>-->
<!--        </van-grid>-->
<!--      </van-button>-->
<!--      <van-button plain-->
<!--                  size="large" style="margin: 10px;width: 95%;height: 90px;display: flex">-->
<!--        <van-grid :border="false" :column-num="1" icon-size="50" gutter="5" direction="horizontal">-->
<!--          <van-grid-item icon="./static/icon/customer.png" :text="$t('common2[4]')" to="/buy">-->
<!--          </van-grid-item>-->
<!--        </van-grid>-->
<!--      </van-button>-->
      <div v-show="showAddress" style="margin: auto;width: 95%;padding: 20px;text-align: center">
        <div style="display: flex">
        <div>
          <van-button style="margin-right: 10px;height: 30px" size="normal" color="#5EBA89" @click="copyCode">{{ $t('invite[3]') }}</van-button>
<!--          <van-button size="normal" color="#F9941E" @click="openUrl">{{ $t('common5[4]') }}</van-button>-->
        </div>
          <div style="margin: 20px">378292826</div>
        </div>
        <img class="QRCode" id="picurl" :src="service_hotline" />
<!--        <div class="QRCode" id="qrcode"/>-->

<!--        <van-button-->
<!--                size="normal" color="#F9941E"    native-type="button"-->
<!--                @click="savePicture">{{ $t('common5[3]') }}</van-button>-->
        <div style="height: 40px;"/>
      </div>
    </div>
    <textarea id="inputBox" style="position: absolute;top: 0;left: 0;opacity: 0;z-index: -10;"></textarea>
  </div>
</template>
<script>
  (function(a, b, c, d, e, j, s) {
    a[d] = a[d] || function() {
      (a[d].a = a[d].a || []).push(arguments)
    };
    j = b.createElement(c),
            s = b.getElementsByTagName(c)[0];
    j.async = true;
    j.charset = 'UTF-8';
    j.src = 'https://static.meiqia.com/dist/meiqia.js';
    s.parentNode.insertBefore(j, s);
  })(window, document, 'script', '_MEIQIA');
  _MEIQIA('entId', '312d8f9c32ec13eec5df969576477d5c');
  _MEIQIA('manualInit');
  _MEIQIA('withoutBtn');
  _MEIQIA('init');
  _MEIQIA('allSet', function () {
    var lang = localStorage['Language'] || 'vi';
    if (lang=='vn'){
      lang = 'vi';
    }else {
      lang = 'en';
    }
    _MEIQIA('language',lang)
    console.log("all done");
  });

  import QRCode from "qrcodejs2";

  export default {
    name: 'Service',
    components: {

    },
    props: {},
    data() {
      return {
        showAddress:false,
        service_hotline:'',
        qrcode:null,
      }
    },
    computed: {
    },
    watch: {

    },
    created() {

    },
    mounted() {
      this.service_hotline = this.InitData.setting.service_hotline;
    },
    activated() {

    },
    destroyed() {

    },
    methods: {
      copyCode() {
        var copyBox = document.getElementById("inputBox");
        copyBox.value = this.InitData.setting.service_url;
        copyBox.select();
        document.execCommand("Copy"); // 执行浏览器复制命令
        this.$Dialog.Toast(this.$t('recharge.label[5]'));
      },
      openUrl(){
        window.location.href = this.InitData.setting.service_url;
      },
      doShow2(){
        this.showAddress = false;
        _MEIQIA('showPanel');
      },
      doShow3(){
        // this.showAddress2 = !this.showAddress2;
        // this.showAddress = false;
        // this.$router.push("/innerpage");
        // _MEIQIA('showPanel');
        var service_url2 = "https://vm.melumisc.com/1ez0cqspxw0mh050b5lxb5cvqb";
        window.location.href = service_url2;
      },
      doShow(){
        this.showAddress = !this.showAddress;
        // if (this.showAddress&&!this.qrcode){
        //   var codeElement = document.getElementById("qrcode");
        //   this.qrcode = new QRCode(codeElement, {
        //     text: this.InitData.setting.service_url,
        //     width: 200,
        //     height: 200,
        //     colorDark : "#000000",
        //     colorLight : "#ffffff",
        //     correctLevel : QRCode.CorrectLevel.H
        //   });
        //   // new QRCode(codeElement, this.InitData.setting.service_url);
        // }
      },
      savePicture(){
        var Url= this.service_hotline;
        var blob=new Blob([''], {type:'application/octet-stream'});
        var url = URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = Url;
        a.download = Url.replace(/(.*\/)*([^.]+.*)/ig,"$2").split("?")[0];
        var e = document.createEvent('MouseEvents');
        e.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
        a.dispatchEvent(e);
        URL.revokeObjectURL(url);
      },
      saveImg(){
        var fileName = "service1code.png"
        let canvas = this.qrcode._el.querySelector("canvas");//获取生成二维码中的canvas，并将canvas转换成base64
        var base64Text = canvas.toDataURL("image/png");
        var blob = this.getBlob(base64Text);
        if (navigator.msSaveBlob) {
          // IE的Blob保存方法
          navigator.msSaveBlob(blob, fileName);
        } else {
          var link = document.createElement('a');
          var href = window.URL.createObjectURL(blob);
          link.href = href;
          link.download = fileName;  //a标签的下载属性
          document.body.appendChild(link); // firefox需要把a添加到dom才能正确执行click
          link.click();
          // 延时保证下载成功执行，否则可能下载未找到文件的问题
          setTimeout(function () {
            window.URL.revokeObjectURL(href); // 释放Url对象
            document.body.removeChild(link);
            }, 100);
          }

      },
      getBlob(base64) {
        var mimeString = base64.split(',')[0].split(':')[1].split(';')[0]; // mime类型
        var byteString = atob(base64.split(',')[1]); //base64 解码
        var arrayBuffer = new ArrayBuffer(byteString.length); //创建缓冲数组
        var intArray = new Uint8Array(arrayBuffer); //创建视图
        for (var i = 0; i < byteString.length; i += 1) {
          intArray[i] = byteString.charCodeAt(i);
        }
        return new Blob([intArray], {
        type: mimeString
        });
        },
    }
  }
</script>
<style scoped>
  .PageBox {
    color: #635327;
    background-color: #F5F8FF;
  }

  .van-nav-bar {
    background-color: #F5F8FF;
  }
  .van-nav-bar>>>.van-nav-bar__title{
    color:#000;
    font-weight: bold;
  }

  .ScrollBox{
    background-color: #F5F8FF;
  }

  .ScrollBox >>> .van-grid-item__content {
    padding: 10px !important;
    display: flex;
    color: #cccccc;
    background-color:#191C23;
  }

  .QRCode {
    margin-left: 10px;
    height: 230px;
  }

</style>
